
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































.award .duo__header__headline {
  color: #d2d2d2;
}
